<template>
  <page-main
    title="Basic company information"
    nextText="Position"
    buttonTabIndex="6"
    eventName="nav"
    @nav="nav('/WorkRoles/' + key)"
    @save="save()"
  >
    <template v-slot:aside>
      Core information about your work experiences: who you worked for, where,
      and in what industry.
      <ul>
        <li>
          Make sure if you are adding multiple records with the same company to
          use the drop-down option to keep the spelling consistent. This will
          help your finished resume look clean - we will group your work
          experiences under one business header if you held multiple roles with
          the same company back-to-back.
        </li>
        <li>
          If you had multiple positions with the same company, list each
          position as separate work record.
        </li>
      </ul>
    </template>
    <template v-slot:nav>
      <v-system-bar color="accent" height="5" class="mt-4"></v-system-bar>
      <modal cardTitle="Example" ctaText="Need an example?">
        <ul class="font-italic">
          <li>
            <span class="font-weight-bold">Example #1:</span>
            <ul>
              <li>
                <span class="font-weight-bold"
                  >Company or Organization Name:</span
                >
                Tech Company A
              </li>
              <li>
                <span class="font-weight-bold">City:</span>
                Chicago
              </li>
              <li>
                <span class="font-weight-bold">State:</span>
                IL
              </li>
              <li>
                <span class="font-weight-bold">Country:</span>
                USA
              </li>
              <li>
                <span class="font-weight-bold"
                  >What industry is this company in?:</span
                >
                Technology
              </li>
            </ul>
          </li>
        </ul>
      </modal>
      <modal cardTitle="Tips" ctaText="Need a few tips?" :accentButton="true">
        <r-tip-container :tips="tips"></r-tip-container>
      </modal>
    </template>
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-single-select
            label="Company or organization name"
            auto-select-first
            :items="validCompanies"
            v-model="companyName"
            tabindex="1"
            v-on:input="updateSelectedCompany"
          ></r-single-select>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="companyCity"
            label="City"
            tabindex="2"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="companyState"
            label="State"
            tabindex="3"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="companyCountry"
            label="Country"
            tabindex="4"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-single-select
            v-model="industry"
            :items="baseTargetIndustries"
            :label="`What industry is ${localCompanyName} in?`"
            tabindex="5"
          >
          </r-single-select>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import * as disp from "../utility/display";
import { mapFields } from "vuex-map-fields";
import { mapActions, mapGetters, mapMutations } from "vuex";

// @ is an alias to /src
export default {
  name: "WorkCompanyGeneral",
  data: () => ({
    currentDisplay: "Present",
    validCompanies: [],
  }),
  components: {},
  mounted() {
    let pageKey = this.$route.params.key;
    if (this.key != pageKey) {
      if (!this.doesKeyExist(pageKey)) {
        this.createEntry({ key: pageKey });
      }
      this.key = pageKey;
    }
    this.validCompanies = this.fullList
      .filter((company) => !disp.IsNullorWhitespace(company.general))
      .map((company) => {
        return company.general;
      });
  },
  methods: {
    save() {
      this.saveWork();
    },
    ...mapMutations("work", ["createEntry"]),
    ...mapActions("work", ["saveWork"]),
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    updateSelectedCompany() {
      const key = this.fullList
        .filter((company) => company.general === this.companyName)
        .map((company) => company.key)[0];
      const company = this.workDict[key];

      this.companyCity = company.workGeneral.companyCity;
      this.companyState = company.workGeneral.companyState;
      this.companyCountry = company.workGeneral.companyCountry;
      this.industry = company.workGeneral.industry;
    },
  },
  computed: {
    ...mapGetters("work", ["fullList", "doesKeyExist"]),
    ...mapFields("work", {
      key: "key",
      display: "workGeneral.display",
      companyName: "workGeneral.companyName",
      companyCity: "workGeneral.companyCity",
      companyState: "workGeneral.companyState",
      companyCountry: "workGeneral.companyCountry",
      industry: "workGeneral.industry",
      workDict: "workDict",
    }),
    ...mapFields("targetIndustry", ["baseTargetIndustries"]),
    localCompanyName() {
      if (disp.IsNullorWhitespace(this.companyName)) {
        return "this company";
      } else {
        return this.companyName;
      }
    },
    tips() {
      return [
        "When adding your state and country information, be consistent with abbreviations between your work records.",
      ];
    },
  },
};
</script>
